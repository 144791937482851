<template>
  <section class="background--views">
    <v-container class="login--container d-flex justify-center">
      <v-row class="d-flex justify-center align-center ma-3">
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="rounded-lg white pa-16 elevation-20"
        >
          <div class="mb-3">
            <icon-logo class="logo"></icon-logo>
          </div>
          <div class="mb-6">
            <h1 class="text-h6 primary--text text-center font-weight-black">
              INICIO DE SESIÓN
            </h1>
          </div>

          <v-form v-model="valid" @submit.prevent="login()">
            <div>
              <p class="text-subtitle-2 font-weight-regular mb-2">Usuario</p>
              <v-text-field
                v-model="user.username"
                class="rounded-0"
                dense
                height="50"
                placeholder="Ingrese su usuario"
                @input="error = null"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </div>
            <div>
              <p class="text-subtitle-2 font-weight-regular mb-2">Contraseña</p>
              <v-text-field
                v-model="user.password"
                class="rounded-0"
                dense
                height="50"
                placeholder="Ingrese su contraseña"
                @input="error = null"
                type="password"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </div>
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
            <div class="d-flex justify-center pb-13">
              <v-btn
                :disabled="!valid"
                :loading="isLoading"
                class="font-weight-regular"
                type="submit"
                large
                depressed
                color="primary"
                >INICIAR SESION</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
// Domain
import Auth from "../domain/Auth";

export default {
  components: {
    IconLogo: () => import("../components/icons/IconLogo")
  },
  data: () => ({
    valid: false,
    error: null,
    user: {
      username: process.env.VUE_APP_USERNAME,
      password: process.env.VUE_APP_PASSWORD
    },
    rules: {
      required: val => !!val || "Este campo es requerido",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo electrónico.";
      }
    },
    isLoading: false
  }),
  methods: {
    async login() {
      this.isLoading = true;
      this.error = null;
      try {
        await Auth.login(this.$store, this.user);
        this.$router.push("/");
      } catch ({ data }) {
        this.error = data.error;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login--container {
  height: 100%;
}

.logo {
  max-width: 16rem;
  margin: 0 auto;
  display: block;
}
</style>