import AuthRepository from "../repository/Auth.repository";
import { setCredentialsLocalStorage } from "../common/HandleAuth";

export default class Auth {
  static async login(store, credentials) {
    return new Promise((resolve, reject) => {
      AuthRepository.login(credentials)
        .then((res) => {
          // Guardar en localStorage
          setCredentialsLocalStorage(res.data);
          
          // Llamar a la mutación para almacenar el usuario en Vuex
          store.commit('setUser', res.data.user);
          
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  }
}
